import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API of userList
const COUPONS_URL = `${process.env.REACT_APP_BASE_URL}/coupon/used-coupons`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface CouponsListProps {
  data: any[];
  status: boolean | null;
  message: string | null;
}
 
const initialState: CouponsListProps = {
  data: [],
  status: null,
  message: null,
};

export const CouponSlice = createSlice({
  name: 'CouponSlice',
  initialState,
  reducers: {
    couponsListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const { couponsListSuccess } = CouponSlice.actions;

export const fetchCoupnsList =
  (
    page: number,
    rowsPerPage: number,
    employee_id: any,
    start_date: any | null,
    end_date: any | null,
    search: string,
  ) =>
  async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${COUPONS_URL}?page=${page}&limit=${rowsPerPage}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }

    if (employee_id) {
      api_url = `${api_url}&employee_id=${employee_id}`;
    }

    if (start_date) {
      api_url = `${api_url}&start_date=${start_date}`;
    }

    if (end_date) {
      api_url = `${api_url}&end_date=${end_date}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        couponsListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      console.log(error);
    }
  };

export default CouponSlice.reducer;