import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import { combineReducers } from 'redux';
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';

// Auth slice
import authReducer from './apps/auth/LoginSlice';

// Dashboard
import dashboardReducer from './dashboard/dashboard';

// Private slice
import userReducer from './apps/UserListSLice/UserListSlice';
import roleReducer from './apps/rolesSlice/RolesSlice';
import modelReducer from './apps/models/ModelSlice';
import partsReducer from './apps/partsSlice/PartsSlice';
import designReducer from './apps/designSlice/designSlice';
import vendorReducer from './apps/vendorSlice/vendorSlice';
import permissionReducer from './apps/permissions/PermissionsSlice';
import clientsReducer from './apps/client/ClientSlice';
import orderReducer from './apps/order/orderSlice';
import qrCodeReducer from './apps/QRCode/QRCode';
import productReducer from './apps/products/ProductsSlice';
import designationReducer from './apps/designation/designationSlice';
import departmentReducer from './apps/department/departmentSlice';
import TaxiPassReducer from './apps/taxiPass/taxiPassSlice';
import GatePassReducer from './apps/gatePass/gatePassSlice';

// slice
import ECommerceSlice from '../store/apps/eCommerce/ECommerceSlice';
import helperReducer from './apps/helper/HelperSlice';
import CouponSlice from './apps/coupons/CouponSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,

    // auth reducer
    auth: authReducer,

    // Dashboard
    dashboard: dashboardReducer,

    // private reducers
    user: userReducer,
    roles: roleReducer,
    models: modelReducer,
    parts: partsReducer,
    design: designReducer,
    vendor: vendorReducer,
    permission: permissionReducer,
    clients: clientsReducer,
    order: orderReducer,
    qrcode: qrCodeReducer,
    product: productReducer,
    designation: designationReducer,
    department: departmentReducer,
    taxiPass: TaxiPassReducer,
    gatePass: GatePassReducer,
    coupons: CouponSlice,
    helper: helperReducer,
  },
});

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  ecommerceReducer: ECommerceSlice,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
